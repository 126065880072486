import * as React from 'react';

interface UnescoLogoProps {
  width: number;
  height: number;
}

export const UnescoLogo = ({ width, height }: UnescoLogoProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m249.3 92.8c-86.4 0-156.5 70-156.6 156.4 0 77.6 56.8 143.5 133.6 154.9 1.4 0.3 2.9 0.5 4.3 0.5 6.2 0.1 11.2-4.9 11.3-11.1v-77.3l-66.6-66.5 74.8-74.8 74.8 74.8-66.4 66.4 0.3-0.3v76.6c0 8.4 5 13.4 12.1 11.8 85.6-12.1 145.2-91.3 133.1-176.9-10.8-77.1-76.8-134.4-154.7-134.5zm33.6 283.9v-49.3l-0.4-0.2 76.7-77.1-109.7-109.5-109.7 109.6 78.5 78.5v47.8c-70.8-18-113.5-90-95.5-160.8s90-113.5 160.8-95.5 113.5 90 95.5 160.8c-12 47.2-49 83.9-96.2 95.7z" />
    <path
      id="l"
      d="m77.3 148.2c-3.4-2.1-5.5-3.4-8.2-4.9 0.5-0.6 1-1.3 1.7-2.3 0.6-1 1.7-3 2.6-4.3 1.7-2.8 3.1-3.9 4.4-4.3 1.7-0.6 3.6-0.3 5.1 0.7 4.5 2.9 4.2 7.1 1.4 11.5l-1 1.5c2.9 1.9 5.8 3.7 8.9 5.4-0.5 0.6-1.1 1.3-1.7 2.3-0.5 0.8-1 1.6-1.4 2.5-2.4-1.8-4.7-3.4-7.9-5.4zm-2.9-9.7c-0.3 0.4-0.5 0.8-0.7 1.2l8.1 5.3c1.7-1.8 1.6-4.7-0.2-6.4-0.3-0.3-0.7-0.6-1.2-0.8-3-1.9-4.8-1.1-5.9 0.6h-0.1z"
    />
    <path
      id="c"
      d="m110.9 126c-0.8 0.8-1.4 1.5-2 2.3-0.6 0.7-1.1 1.6-1.7 2.4-2.3-0.9-4.7-1.7-7-2.5-0.9 1.1-1.9 2.2-2.8 3.4l-2.2 2.9c1.3 2.2 2.7 4.3 4.1 6.2-0.4 0.4-0.8 0.7-1.1 1.1s-0.6 0.9-0.9 1.5c-1.9-3.7-8.4-15.4-12.7-22.7 0.4-0.3 0.7-0.6 1-0.9s0.5-0.7 0.7-1.1c7.8 2.5 18.5 5.9 24.6 7.4zm-12.8 1.5c-2.9-1-5.6-1.9-8.4-2.7 1.4 2.6 2.9 5.1 4.4 7.7z"
    />
    <path
      id="f"
      d="m102 109.2c-0.7-0.7-1.5-1.4-2.2-2-0.9 1-2.8 3.4-4.1 4.9-0.3-0.4-0.6-0.8-0.9-1.2-0.4-0.4-0.8-0.7-1.2-0.9 2.3-2.2 3.4-3.4 6.4-6.3 3.1-3 4-4.2 6-6.4 0.3 0.4 0.6 0.8 1 1.2s0.8 0.7 1.2 0.9c-1.6 1.3-3.2 2.6-4.6 4.1 0.7 0.8 1.4 1.5 2.1 2.1l6.2 6c2.8 2.7 4.7 4.5 7.1 6.5-0.6 0.5-1.3 1.1-2.1 1.9s-1.4 1.6-1.8 2.1c-2-2.2-3.9-4.2-6.7-6.9z"
    />
    <path
      id="b"
      d="m123.1 99.4-0.3 0.2 0.9 1.1c2.4 3 4.2 5 6.3 7.2-0.8 0.5-1.6 1.1-2.3 1.7-0.9 0.7-1.5 1.4-2.1 1.9-1.8-2.4-3.4-4.6-5.9-7.6l-3-3.6c-2.4-3-4.2-5-6.3-7.2 0.8-0.5 1.6-1.1 2.3-1.7 1-0.8 2-1.8 3.8-3.4 2.7-2.2 3.9-2.8 5.3-2.9 1.8-0.1 3.5 0.6 4.6 2.1 2.1 2.4 2.1 5.9 0 8.3 4.2 1.6 8.8 3 13.2 4.4-0.9 0.6-1.7 1.2-2.5 1.8s-1.6 1.4-2.3 2.1zm-7.1-8.3c1.9 2.5 3.9 4.9 5.9 7.3l0.6-0.4c2-1.3 2.5-4 1.1-6-0.2-0.3-0.4-0.5-0.6-0.7-2.6-3.2-4.1-2.5-7 0z"
    />
    <path
      id="a"
      d="m134.6 86.4c-2.2-3.4-3.7-5.4-5.6-7.8 0.7-0.4 1.5-0.8 2.4-1.4s1.6-1.2 2.2-1.7c1.6 2.6 3 4.8 5.2 8.1l2.7 3.9c2.2 3.4 3.8 5.4 5.7 7.8-0.8 0.4-1.7 0.9-2.4 1.4-1 0.7-1.6 1.3-2.2 1.7-1.5-2.6-3-4.9-5.2-8.1-0.1-0.1-2.8-3.9-2.8-3.9z"
    />
    <path
      id="k"
      d="m147.1 76.3c1.8 5 4 9.8 6.1 14.6-0.5 0.1-1.1 0.3-1.5 0.6-0.5 0.3-0.9 0.6-1.4 0.9-2.4-7.7-5.1-15.3-8-22.8 0.5-0.1 0.9-0.3 1.3-0.5s0.8-0.5 1.2-0.8c4.8 3.7 10.3 7.8 14.8 10.9 0-5.8-0.2-12.4-0.6-18.4 0.5-0.1 0.9-0.3 1.4-0.5 0.4-0.2 0.9-0.5 1.2-0.8 4.5 6.7 10.7 15.8 13.8 19.7-0.9 0.3-1.7 0.7-2.6 1.2-0.8 0.4-1.7 0.9-2.4 1.5-2.4-4.2-5.7-9.1-8.7-13.4 0.2 5.9 0.4 11.8 0.7 17.8-0.2 0-0.4 0.1-0.6 0.2l-0.5 0.3c-4.6-3.4-9.3-6.9-14-10.4z"
    />
    <path
      id="d"
      d="m182.1 51.1c6.9-2.4 13.7-0.4 16.4 7.5 2.2 6.6-1.5 13.7-8.1 15.9-0.2 0-0.3 0.1-0.5 0.1-6.7 2.3-13.7 0.7-16.4-7.5-2.2-6.5 1.3-13.6 7.8-15.8 0.3 0 0.6-0.1 0.8-0.2zm0.6 1.9c-4.5 1.5-5.6 6.9-3.7 12.5 2.3 6.9 6.4 8.3 10.1 7 3.7-1.2 6.3-5.8 3.8-12.9-1.9-5.6-6-8.1-10.2-6.6z"
    />
    <path
      id="j"
      d="m202.2 45.5c0.5 0.1 1.1 0.1 1.6 0s1-0.2 1.5-0.4c5.9 4.5 11.7 9 17.7 13.4-1-5.4-2.7-14.7-3.2-16.2 0.5 0 1-0.1 1.5-0.2s1-0.2 1.5-0.4c1.2 10.1 2.1 14.5 3.7 23.5-0.5-0.1-1-0.1-1.4 0s-0.9 0.2-1.3 0.4c-5.6-4.5-11.6-9-17.8-13.6 0.4 2.6 0.9 5.4 1.4 8 1 5.4 1.4 7.3 1.8 8.6h-1.6c-0.5 0.1-1 0.3-1.5 0.5 0-1-1.1-9.9-2.3-16.8-0.7-3.1-1.3-5.2-1.6-6.8z"
    />
    <path d="m232 50.7c-0.3-3.9-0.5-6.5-1-9.5h2.9c1.1 0 2.1-0.3 2.8-0.4 0 3.1 0 5.7 0.4 9.6l0.3 4.7c0.3 3.9 0.6 6.5 1 9.5-0.9-0.1-1.9-0.1-2.9 0-1.1 0-2 0.3-2.8 0.4 0-3.1-0.2-5.7-0.4-9.6z" />
    <path d="m256.7 40.1c7.3 0.2 13 4.5 12.7 12.8-0.3 6.9-6.1 12.3-13.1 12h-0.6c-7.3-0.2-13-4.1-12.7-12.8 0.2-6.9 6-12.2 12.9-12 0.2-0.1 0.5-0.1 0.8 0zm-0.2 1.9c-4.7 0-7.6 4.5-7.9 10.5-0.2 7.3 3.1 10.1 6.9 10.1 3.9 0 7.9-3.4 8.2-10.7 0.2-6-2.8-9.8-7.2-10.1z" />
    <path d="m289.4 52.2c-2 4.9-3.4 10.1-5.1 14.9l-1.5-0.6c-0.5-0.1-1.1-0.2-1.6-0.2 3.4-7.4 6.3-14.8 9.2-22.3 0.4 0.2 0.9 0.4 1.3 0.5h1.4c1.1 6 2.5 12.6 3.8 18 3.9-4.3 8.1-9.4 11.8-14.1l1.3 0.6h1.4c-1 7.9-2.5 19-2.9 23.9-0.9-0.3-1.8-0.6-2.7-0.8s-1.9-0.4-2.8-0.5c0.9-4.7 1.7-10.6 2.5-15.9-3.8 4.5-7.6 9.1-11.3 13.8-0.2-0.1-0.4-0.2-0.6-0.2h-0.6c-1.1-5.6-2.3-11.4-3.5-17.1z" />
    <path d="m315.7 66.7c1.4-3.4 2.2-5.1 3.2-7.4 1.4-3.4 2.3-5.6 2.6-6.7 1.7 0.9 3.4 1.7 5.2 2.2-1.9 3.6-3.6 7.2-5.1 11-3.4 7.8-1.6 10.1 2.6 11.7 5.1 2.1 7.8-3.4 10.3-9.2 1.2-2.9 2.3-5.9 3.4-8.9 0.5 0.3 1 0.6 1.5 0.8s1.1 0.4 1.6 0.5c-1 1.8-3.1 6.3-5.4 11.8-3.1 7.4-7.6 9.1-13.8 6.5-5.4-2.3-8.7-6.5-6.1-12.5z" />
    <path d="m349.17 65.1c0.4 0.4 0.8 0.7 1.3 0.9 0.4 0.3 0.9 0.5 1.4 0.7 1.4 7.3 2.9 14.5 4.4 21.8 2.9-4.7 7.7-12.8 8.3-14.2 0.8 0.7 1.7 1.3 2.7 1.6-5.7 8.3-8 12.3-12.7 20.1-0.4-0.3-0.7-0.6-1.1-0.9-0.4-0.2-0.8-0.4-1.2-0.5-1.2-7.2-2.6-14.6-4.3-22.2-1.4 2.2-2.9 4.6-4.2 6.7-2.9 4.7-3.8 6.5-4.3 7.6-0.4-0.4-0.8-0.7-1.3-1s-0.9-0.5-1.4-0.7c0.7-0.8 5.7-8.2 9.4-14.2 1.6-2.7 2.5-4.5 3.4-6z" />
    <path
      id="i"
      d="m368.37 88.6c2.4-3 4.1-5.1 5.8-7.6 0.6 0.5 1.2 1.2 2.1 1.9s2.6 1.9 4.9 3.8c6.1 5 8.7 10.4 3.4 17.1-3.9 5.3-11.4 6.5-16.7 2.6-0.2-0.1-0.3-0.3-0.5-0.4-2.7-2.2-4.4-3.8-5.8-4.9-0.7-0.6-1.5-1.2-2.3-1.7 2.1-2.2 3.8-4.3 6.2-7.2l3-3.6zm0.5 7.4c-1.2 1.4-3.9 4.7-4.2 5.2 0.5 0.5 1.2 1.1 2.3 2 4 3.4 8.8 2.7 13.4-2.9 3.6-4.4 3.4-9.1-0.3-12.1-0.9-0.8-1.9-1.5-2.9-2.1-0.4 0.5-3.1 3.7-4.3 5.2l-3.9 4.7z"
    />
    <path d="m389.97 107.2c2.8-2.7 4.6-4.7 6.7-6.9 0.5 0.6 1.1 1.3 1.9 2.1s1.5 1.4 2.1 1.9c-2.3 2-4.2 3.8-7 6.7l-3.4 3.4c-2.8 2.7-4.6 4.7-6.7 6.9-0.5-0.6-1.1-1.3-1.9-2.1s-1.5-1.4-2.1-1.9c2.3-2 4.2-3.8 7-6.7z" />
    <path d="m400.37 140.6c-0.7-0.9-1.2-1.7-1.8-2.4s-1.4-1.4-2-2.2c1.3-2 2.7-4.2 3.9-6.3-0.9-1.2-1.8-2.3-2.8-3.4s-1.5-1.8-2.3-2.8c-2.4 0.8-4.7 1.7-6.9 2.7-0.3-0.5-0.6-0.9-0.9-1.4-0.4-0.4-0.8-0.9-1.2-1.2 4.1-1.1 16.8-5 24.8-7.7 0.2 0.4 0.4 0.8 0.7 1.1s0.6 0.6 1 0.9c-4.3 7.3-9.9 17.1-12.5 22.7zm1.2-12.9c1.5-2.5 3-5 4.4-7.6-2.8 0.8-5.6 1.7-8.4 2.7z" />
    <path
      id="h"
      d="m414.17 135.8c3.4-2.1 5.4-3.6 7.8-5.5 0.4 0.7 0.8 1.5 1.4 2.4s1.2 1.7 1.7 2.3c-2.7 1.5-4.9 2.9-8.2 5l-3.9 2.6c-2.1 1.4-4.3 2.9-5.8 3.9 1.5 2.2 3 4.3 4.7 6.3-0.5 0.2-0.9 0.5-1.4 0.7-0.4 0.3-0.8 0.6-1.1 0.9-0.7-1-1.4-2.5-3.4-5.1-3.1-4.7-3.7-5.4-4.1-6 2.7-1.5 4.9-2.9 8.2-5l3.9-2.6z"
    />
    <path
      id="g"
      d="m72.7 169.6c-0.7 1.7-2.7 2.5-4.4 1.7-1.7-0.7-2.5-2.7-1.7-4.4 0.7-1.7 2.7-2.5 4.4-1.7h0.1c1.6 0.8 2.3 2.8 1.6 4.4z"
    />
    <g>
      <path d="m64.1 200.9c-4.2-2.9-10.8-7.5-15.2-10.2 0.3-0.6 0.5-1.1 0.6-1.7l0.3-1.8c6.4 0 23.8-0.6 25.3-0.7-0.3 1-0.7 1.9-0.9 2.8s-0.4 1.8-0.5 2.8h-18.4c5.2 3.4 12.8 8.6 15.7 10.4-0.3 0.5-0.5 1.1-0.6 1.7s-0.2 1.1-0.3 1.7c-3.2-0.2-13.8 0.8-18.7 0.9 5.3 3.4 13.4 8.3 15.9 9.6-0.2 0.4-0.4 0.9-0.6 1.4-0.1 0.5-0.2 1-0.2 1.6-2.4-1.6-14.5-9.8-21.5-13.9 0.2-0.6 0.4-1.1 0.6-1.7l0.3-1.8c6 0 12-0.4 18.2-1z" />
      <path d="m76 344.5c-0.8-1.6-1.5-3.4-2.3-4.9s-1.7-3.1-2.6-4.7l-1.8 0.9c-3.5 1.8-5.9 3.1-8.6 4.7-0.3-0.7-0.6-1.6-1.1-2.7-0.4-0.9-0.9-1.7-1.5-2.5 2.9-1.2 5.3-2.4 8.8-4.2l4.3-2.1c3.6-1.8 5.9-3.1 8.6-4.7 0.3 0.7 0.6 1.6 1.1 2.7 0.4 0.9 0.9 1.7 1.5 2.5-2.8 1.2-5.3 2.4-8.8 4.2 0.5 1.1 1.2 2.6 2.3 4.8s1.8 3.6 2.5 4.7c3.4-1.8 5.9-3.1 8.5-4.6 0.3 0.9 0.7 1.8 1.1 2.7 0.5 1.1 1.1 1.8 1.5 2.5-2.9 1.2-5.3 2.4-8.9 4.2l-4.3 2.2c-3.4 1.8-5.9 3.1-8.6 4.7-0.3-0.9-0.7-1.8-1.1-2.7s-0.9-1.7-1.5-2.5c2.9-1.2 5.3-2.4 8.9-4.2z" />
      <path d="m172.3 424.4c-0.4-2.8-2.1-5.1-4.7-6.3-3.9-2-9.6-0.8-13 5.8-2.5 5-1.7 10.1 3.2 12.6 1.2 0.6 2.4 1 3.7 1.1 0.5-0.7 0.9-1.4 1.2-2.1 1.7-3.4 2.3-4.9 2.5-5.5 0.7 0.5 1.5 1 2.2 1.3 0.8 0.5 1.7 0.8 2.7 1.1-1.1 1.6-2.1 3.4-3 5.1-0.7 1.3-1.5 3-1.6 3.4-3.2-0.5-6.2-1.5-9.1-2.9-7.2-3.7-10.3-10.4-7.1-16.8 4.1-8 11.7-9.1 19.1-5.4 2.7 1.3 5 3.3 6.7 5.7-0.9 0.9-1.8 1.8-2.5 2.8l-0.4-0.2z" />
      <path
        id="e"
        d="m404.17 369.9c-3.2-2.3-5.4-3.9-8.1-5.5 1.2-1.7 2.6-3.4 3.8-5 2.1-2.8 3.8-5.2 4.2-6.1 0.3 0.4 0.7 0.7 1.1 1s0.9 0.6 1.4 0.8c-1.7 2-4.4 5.3-5.1 6.2 2 1.6 4.1 3.2 6.1 4.7 1.7-2.5 3-4.4 4.5-6.7 0.3 0.4 0.7 0.8 1.2 1.1 0.4 0.3 0.8 0.5 1.3 0.7-1.7 2-3.4 4.1-5 6.3l3.6 2.7c1.2 0.9 2.4 1.7 3.6 2.5 1.6-2.1 3.1-4.4 4.4-6.7 0.4 0.4 0.8 0.7 1.2 1s0.8 0.6 1.3 0.8c-0.8 1-1.8 2.2-3.8 4.9-1.5 2-3 4-4.3 6.1-2.4-2-4.5-3.7-7.7-6z"
      />
    </g>
    <use
      transform="matrix(.48833 .89577 -.89577 .48833 323 302.36)"
      xlinkHref="#l"
    />
    <use
      transform="matrix(.64632 .78322 -.78322 .64632 293.55 291.17)"
      xlinkHref="#c"
    />
    <use
      transform="matrix(.78967 .64088 -.64088 .78967 261.86 288.12)"
      xlinkHref="#f"
    />
    <use
      transform="matrix(.91401 .44455 -.44455 .91401 227.07 297.85)"
      xlinkHref="#b"
    />
    <use
      transform="matrix(.98036 .27193 -.27193 .98036 202.26 314.06)"
      xlinkHref="#a"
    />
    <use
      transform="matrix(1.016 .037463 -.037463 1.016 178.06 346.19)"
      xlinkHref="#k"
    />
    <use
      transform="matrix(.82286 -.61803 .61803 .82286 183.37 471.52)"
      xlinkHref="#j"
    />
    <use
      transform="matrix(-.66191 -.78816 .78816 -.66191 619.13 607.56)"
      xlinkHref="#i"
    />
    <use
      transform="matrix(-.0010562 1.0145 -1.0145 -.0010562 197.25 251)"
      xlinkHref="#b"
    />
    <use
      transform="matrix(-.62215 .82217 -.82217 -.62215 210.01 217.61)"
      xlinkHref="#b"
    />
    <use
      transform="matrix(.074296 1.0127 -1.0127 .074296 260.9 308.21)"
      xlinkHref="#c"
    />
    <use
      transform="matrix(.061139 1.0152 -1.0152 .061139 229.62 284.19)"
      xlinkHref="#f"
    />
    <use
      transform="matrix(1.0096 -.12588 .12588 1.0096 229.22 328.81)"
      xlinkHref="#a"
    />
    <use
      transform="matrix(.47929 -.8974 .8974 .47929 302.12 309.51)"
      xlinkHref="#a"
    />
    <use
      transform="matrix(.18145 1.0011 -1.0011 .18145 172.23 235.69)"
      xlinkHref="#a"
    />
    <use
      transform="matrix(.81282 -.61069 .61069 .81282 254.47 370.18)"
      xlinkHref="#k"
    />
    <use
      transform="matrix(.96752 -.30015 .30015 .96752 163.54 407.38)"
      xlinkHref="#d"
    />
    <use
      transform="matrix(.60388 -.81334 .81334 .60388 276.02 418.84)"
      xlinkHref="#d"
    />
    <use
      transform="matrix(.46171 -.90167 .90167 .46171 -89.152 370.86)"
      xlinkHref="#d"
    />
    <use
      transform="matrix(.31796 -.97875 .97875 .31796 323.21 468.65)"
      xlinkHref="#j"
    />
    <use
      transform="matrix(.83788 .59772 -.59772 .83788 -196.51 -2.4279)"
      xlinkHref="#i"
    />
    <use
      transform="matrix(.64494 -.78435 .78435 .64494 282.02 201)"
      xlinkHref="#c"
    />
    <use
      transform="matrix(-.97434 -.2541 .2541 -.97434 805.91 431.34)"
      xlinkHref="#h"
    />
    <use
      transform="matrix(.91621 .41769 -.41769 .91621 -266.64 -23.649)"
      xlinkHref="#h"
    />
    <use transform="rotate(73.736 250.08 249.71)" xlinkHref="#e" />
    <use transform="rotate(109.14 250.03 248.13)" xlinkHref="#e" />
    <use transform="translate(358.13 -3.391)" xlinkHref="#g" />
    <use transform="translate(138.65 275.4)" xlinkHref="#g" />
  </svg>
);
