import React, { useContext, useState } from 'react';
import { Box, Button, Center, SimpleGrid } from '@chakra-ui/react';
import { useListSongs } from './useListSongs';
import { StateView } from '../../common/StateView';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../app/App';
import { paths } from '../../../util/routes';
import { SongDisplay } from '../common/songDisplay';
import { type SongSummary } from '../common/types';
import { BreadcrumbGroup } from '../../common/breadcrumbs';

export const Home: React.FC = StateView(useListSongs, ({ songSummaries }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const [songs, setSongs] = useState<SongSummary[]>(songSummaries);

  return (
    <Box>
      <BreadcrumbGroup breadcrumbPaths={['home', 'cscsHome']} />
      <Button
        style={{ display: user ? 'block' : 'none' }}
        onClick={() => {
          if (user) {
            navigate(paths.createCscsEntry);
          }
        }}
      >
        Create
      </Button>
      <Center>
        <SimpleGrid minChildWidth="300px" spacing="70px">
          {songs.map((song) => {
            return (
              <SongDisplay
                key={song.songId}
                song={song}
                isAuthenticated={!!user}
                setSongs={setSongs}
              />
            );
          })}
        </SimpleGrid>
      </Center>
    </Box>
  );
});
