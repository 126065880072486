export interface SongSummary {
  songId: string;
  artistName: string;
  songName: string;
  shortDescription: string;
  imageId: string;
  genres: string[];
  genreCategory: string;
}

export enum GenreCategory {
  Pop = 'Pop',
  Rock = 'Rock',
  Metal = 'Metal',
  Hiphop = 'Hip-Hop',
  Electronic = 'Electronic',
  Experimental = 'Experimental',
  Classical = 'Classical',
  Country = 'Country',
  Folk = 'Folk',
  Jazz = 'Jazz',
  Punk = 'Punk',
  Rb = 'R&B',
  Dance = 'Dance',
}
