import { type AxiosRequestHeaders, type AxiosResponse } from 'axios';
import { useAxios } from './useAxios';

export const getRequest = async (
  uri: string,
): Promise<AxiosResponse<any, Error>> => {
  const { get } = useAxios();
  const resp = get(uri);

  return resp;
};

export const postRequest = async (
  uri: string,
  payload: any,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<any, Error>> => {
  const { post } = useAxios();
  const resp = post(uri, payload, headers);
  return resp;
};

export const deleteRequest = async (
  uri: string,
): Promise<AxiosResponse<any, Error>> => {
  const { del } = useAxios();
  const resp = del(uri);
  return resp;
};
