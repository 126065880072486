import React from 'react';

interface ImageUploadProps {
  setImage: React.Dispatch<React.SetStateAction<string>>;
}

const convertBase64 = async (
  file: File,
): Promise<string | ArrayBuffer | null> => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const ImageUpload = ({ setImage }: ImageUploadProps): JSX.Element => {
  return (
    <input
      type="file"
      accept="image/png,image/jpeg"
      onChange={async (e) => {
        if (e.target?.files?.[0]) {
          const base64 = await convertBase64(e.target.files[0]);
          base64 && setImage(base64.toString());
        }
      }}
    />
  );
};
