import { type Response, success, failure, loading } from './response';
import useSWR from 'swr';

export const useCache = <D extends Record<string, any>, E = Error>(
  fetcher: () => Promise<D>,
  key: string[],
): Response<D, E> => {
  const { data, error } = useSWR(key, fetcher);
  if (error) {
    return failure(error);
  }

  if (!data) {
    return loading();
  }
  return success(data.data ? data.data : data);
};

/*
export const useCachedPages = <D, T>(fetcher: () => Promise<D>, getItems: (r: D) => T[], key: string[]): Response<T[], D> => {
    const loadAll = async () => {
        const items: T[] = [];
        let request = fetcher();
        while(request) {
            const response = (await request) as any;
            const responseItems = getItems(response);
            items.push(...responseItems);
            request = response.$response.nextPage();
        }
        return items
    }
    return useCache(loadAll, key)
}
*/
