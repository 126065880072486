import React, { useContext } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { Menu, MenuItem } from '../menu';
import { paths } from '../../../util/routes';
import { Link } from 'react-router-dom';
import { createLoginUrl } from '../../../util/cognito';
import { UserContext } from '../../app/App';
export const Header = (): JSX.Element => {
  const { user } = useContext(UserContext);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={2}
      p={5}
      bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
      borderBottom="1px solid #ebebeb"
    >
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          <Link to={paths.home}>Casey Smith</Link>
        </Text>
      </Box>
      <Menu>
        <MenuItem to={paths.about}>About</MenuItem>
        <MenuItem to={paths.home}>Resume</MenuItem>
        <MenuItem to={paths.projects}>Projects</MenuItem>
        {!user && <MenuItem to={createLoginUrl}>Login</MenuItem>}
      </Menu>
    </Flex>
  );
};
