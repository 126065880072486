import React from 'react';
import { Text, type TextProps } from '@chakra-ui/react';
import { InternalLink } from '../link';
import { type RouteName } from '../../../util/routes';
import { ExternalLink } from '../link/Links';

interface MenuItemProps {
  to: RouteName | string;
  children: React.ReactNode;
  textProps?: TextProps;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  to,
  children,
  textProps,
}) => {
  const text = <Text {...textProps}>{children}</Text>;

  if (to.includes('https')) {
    return <ExternalLink to={to}>{text}</ExternalLink>;
  } else {
    return <InternalLink to={to as RouteName}>{text}</InternalLink>;
  }
};
