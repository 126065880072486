import { useParams } from 'react-router';
import { type RouteParams } from '../../../util/routes';
import { useListYoutubePlaylistItems } from '../../../service/requests';
import { toPlaylist, type Playlist } from './config';
import {
  isSuccess,
  type Response,
  success,
  failure,
} from '../../../service/response';

export const useGetYoutubePlaylist = (): Response<Playlist, Error> => {
  const { playlistId } = useParams<RouteParams<'youtubeRandomizer'>>();

  if (!playlistId) {
    return failure(new Error('Playlist ID is null'));
  }

  const resp = useListYoutubePlaylistItems(playlistId);
  if (isSuccess(resp)) {
    return success({
      ...toPlaylist(resp.data),
    });
  }

  return resp;
};
