import { useDeleteSong as useDeleteSongRequest } from '../../../../service/requests';
import {
  isFailure,
  isSuccess,
  loading,
  type Response,
} from '../../../../service/response';
import { type SongSummary } from '../types';

export const useDeleteSong = (
  songId: string,
  setSongs: React.Dispatch<React.SetStateAction<SongSummary[]>>,
): any => {
  const {
    deleteSongEntry,
  }: { deleteSongEntry: () => Promise<Response<null, Error>> } =
    useDeleteSongRequest({ songId });

  const deleteSong = async (): Promise<Response<null, Error>> => {
    const resp = await deleteSongEntry();

    if (!isFailure(resp) && !isSuccess(resp)) {
      return loading();
    }

    if (isSuccess(resp)) {
      setSongs((songs) => songs.filter((song) => song.songId !== songId));
    }

    return resp;
  };

  return {
    deleteSong,
  };
};
