import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { type RouteName, pageNames, paths } from '../../util/routes';
import { About } from '../csca/about';
import { Home } from '../csca/home';
import { Projects } from '../csca/projects';
import { CscsHome } from '../cscs/home';
import { YoutubeHome } from '../youtubeRandomizer/home';
import { Player } from '../youtubeRandomizer/player';
import { Authenticate } from '../authentication';
import { CreateEntry } from '../cscs/home/createEntry/CreateEntry';
import { Helmet } from 'react-helmet-async';
import { NutritionHome } from '../nutrition/home';
import { Records } from '../records/Records';
import { Photography } from '../photography/Photography';
import { UploadPhoto } from '../photography/upload/UploadPhoto';

interface ElementWithTitleProps {
  component: React.ReactNode;
  path: RouteName;
}

const ElementWithTitle = ({
  component,
  path,
}: ElementWithTitleProps): JSX.Element => (
  <>
    <Helmet>
      <title>{pageNames[path]}</title>
    </Helmet>
    {component}
  </>
);

export function cscaRoutes(): JSX.Element {
  return (
    <Routes>
      <Route
        path={paths.home}
        element={<ElementWithTitle component={<Home />} path="home" />}
      />
      <Route
        path={paths.youtubeRandomizerHome}
        element={
          <ElementWithTitle
            component={<YoutubeHome />}
            path="youtubeRandomizerHome"
          />
        }
      />
      <Route
        path={paths.youtubeRandomizer}
        element={
          <ElementWithTitle component={<Player />} path="youtubeRandomizer" />
        }
      />
      <Route
        path={paths.about}
        element={<ElementWithTitle component={<About />} path="about" />}
      />
      <Route
        path={paths.projects}
        element={<ElementWithTitle component={<Projects />} path="projects" />}
      />
      <Route
        path={paths.cscsHome}
        element={<ElementWithTitle component={<CscsHome />} path="cscsHome" />}
      />
      <Route
        path={paths.authenticate}
        element={
          <ElementWithTitle component={<Authenticate />} path="authenticate" />
        }
      />
      <Route
        path={paths.createCscsEntry}
        element={
          <ElementWithTitle
            component={<CreateEntry />}
            path="createCscsEntry"
          />
        }
      />
      <Route
        path={paths.nutrition}
        element={
          <ElementWithTitle component={<NutritionHome />} path="nutrition" />
        }
      />
      <Route
        path={paths.records}
        element={<ElementWithTitle component={<Records />} path="records" />}
      />
      <Route
        path={paths.photography}
        element={
          <ElementWithTitle component={<Photography />} path="photography" />
        }
      />
      <Route
        path={paths.uploadPhoto}
        element={
          <ElementWithTitle component={<UploadPhoto />} path="uploadPhoto" />
        }
      />
    </Routes>
  );
}
