import React, { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  HStack,
  IconButton,
  Input,
  Link,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { StateView } from '../../common/StateView';
import { useGetYoutubePlaylist } from './useGetYoutubePlaylist';
import {
  defaultPlayerEvent,
  EndState,
  type PlayerEvent,
  shuffle,
  type Video,
} from './config';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbGroup } from '../../common/breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { type ReactInputEvent } from '../../../util/types';

const PLAYER_ERROR = '150';

export const Player: React.FC = StateView(useGetYoutubePlaylist, (data) => {
  let { videos } = data;

  const [queueIndex, setQueueIndex] = useState<number>(0);
  const [displayPlayer, setDisplayPlayer] = useState<boolean>(true);
  const [showList, setShowList] = useState<boolean>(true);
  const [event, setEvent] = useState<any>(undefined);
  const [endState, setEndState] = useState<EndState>(EndState.Restart);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayUnplayable, setDisplayUnplayable] = useState<boolean>(false);
  const [unplayableVideos, setUnplayableVideos] = useState<Video[]>([]);

  const currSongRef = useRef<HTMLTableCellElement>(null);

  const currentVideo = videos[queueIndex];

  const playVideo = (event: PlayerEvent): void => {
    event.target.playVideo();
  };

  const previousVideo = (): void => {
    setEvent(defaultPlayerEvent);
    setQueueIndex((currValue) => nextPlayableVideo(currValue - 1, -1));
  };
  const nextVideo = (): void => {
    setEvent(defaultPlayerEvent);
    if (isLastSong) {
      switch (endState) {
        case EndState.Restart:
          setQueueIndex(0);
          return;
        case EndState.ShuffleAndRestart:
          videos = videos.sort(shuffle);
          setQueueIndex(0);
          return;
        case EndState.End:
          return;
      }
    }
    setQueueIndex((currValue) => nextPlayableVideo(currValue + 1, 1));
  };

  const nextPlayableVideo = (index: number, direction: number): number => {
    while (true) {
      if (videos[index].playable) {
        return index;
      }

      index += direction;
    }
  };

  const setPlayerStatus = (event: PlayerEvent): void => {
    setEvent(event);
    if (event?.data === PLAYER_ERROR) {
      currentVideo.playable = false;
      setUnplayableVideos((currVal: Video[]) => [...currVal, currentVideo]);
      nextVideo();
    } else if (currSongRef.current) {
      currSongRef.current.scrollIntoView();
    }
  };
  const handlePlayClick = (): void => {
    isPlaying ? event.target.pauseVideo() : event?.target.playVideo();
  };

  const isPaused = event?.data === YouTube.PlayerState.PAUSED;
  const isPlaying = event?.data === YouTube.PlayerState.PLAYING;
  const isLoading = event?.data === YouTube.PlayerState.BUFFERING;
  const isUnplayable = event === defaultPlayerEvent && !isPlaying;

  const isLastSong = queueIndex === videos.length - 1;

  const buttonIcon = isPlaying ? (
    <FontAwesomeIcon icon={faPause} />
  ) : isPaused ? (
    <FontAwesomeIcon icon={faPlay} />
  ) : isLoading ? (
    <Spinner />
  ) : (
    <FontAwesomeIcon icon={faTimes} />
  );

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Box>
      <Helmet defer={false}>
        <title>{currentVideo.title}</title>
      </Helmet>
      <BreadcrumbGroup
        breadcrumbPaths={['home', 'youtubeRandomizerHome', 'youtubeRandomizer']}
      />
      <Center>
        <HStack spacing={20}>
          <VStack>
            <Box visibility={displayPlayer ? 'visible' : 'hidden'}>
              {currentVideo.id && currentVideo.title && (
                <YouTube
                  videoId={currentVideo.id}
                  title={currentVideo.title}
                  onReady={playVideo}
                  onEnd={nextVideo}
                  onError={setPlayerStatus}
                  onPlay={setPlayerStatus}
                  onPause={setPlayerStatus}
                  onStateChange={setPlayerStatus}
                  opts={opts}
                />
              )}
            </Box>
            <Container>
              <Text>{currentVideo.title}</Text>
              <Button
                disabled={queueIndex === 0 || isUnplayable}
                onClick={previousVideo}
              >
                Previous
              </Button>
              <IconButton
                disabled={(!isPaused && !isPlaying) || isUnplayable}
                onClick={handlePlayClick}
                aria-label="Play"
                icon={buttonIcon}
              />
              <Button
                disabled={
                  (isLastSong && endState === EndState.End) || isUnplayable
                }
                onClick={nextVideo}
              >
                Next
              </Button>
            </Container>
            <HStack spacing={4}>
              <Checkbox
                isChecked={displayPlayer}
                onChange={(e: ReactInputEvent) => {
                  setDisplayPlayer(e.target.checked);
                }}
              >
                Show player
              </Checkbox>
              <Checkbox
                isChecked={showList}
                onChange={(e: ReactInputEvent) => {
                  setShowList(e.target.checked);
                }}
              >
                Show list
              </Checkbox>
            </HStack>
            <RadioGroup
              onChange={(e: EndState) => {
                setEndState(e);
              }}
              value={endState}
            >
              <Text>Playlist End Options</Text>
              <Stack direction="column">
                <Radio value={EndState.Restart}>Restart</Radio>
                <Radio value={EndState.ShuffleAndRestart}>
                  Shuffle and Restart
                </Radio>
                <Radio value={EndState.End}>End</Radio>
              </Stack>
            </RadioGroup>
          </VStack>
          <VStack visibility={showList ? 'visible' : 'hidden'}>
            <Input
              size="sm"
              placeholder="Search"
              value={searchValue}
              onChange={(e: ReactInputEvent) => {
                setSearchValue(e.target.value);
              }}
            />
            <VStack h="450px" w="350px" overflowY="scroll" overflowX="hidden">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Video</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {videos
                    .map((video, index) => {
                      return (
                        <Tr
                          style={{
                            display: !video.playable ? 'none' : 'block',
                          }}
                          key={video.id}
                          name={video.title}
                        >
                          <Td
                            ref={index === queueIndex ? currSongRef : null}
                            fontWeight={
                              index === queueIndex ? 'bold' : 'normal'
                            }
                          >
                            <Link
                              onClick={() => {
                                setQueueIndex(index);
                              }}
                            >
                              {video.title}
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })
                    .filter(
                      (video) =>
                        video.props.name
                          ?.toLowerCase()
                          .includes(searchValue.toLowerCase()),
                    )}
                </Tbody>
              </Table>
            </VStack>
            <VStack>
              <Link
                onClick={() => {
                  setDisplayUnplayable(true);
                }}
                style={{ display: displayUnplayable ? 'none' : 'block' }}
              >
                Show unplayable videos
              </Link>
              <Table
                style={{ display: !displayUnplayable ? 'none' : 'block' }}
                size="sm"
              >
                <Thead>
                  <Tr>
                    <Th>Unplayable videos</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {unplayableVideos.map((video) => {
                    return (
                      <Tr key={video.id} name={video.title}>
                        <Td>
                          <Text>{video.title}</Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </VStack>
          </VStack>
        </HStack>
      </Center>
    </Box>
  );
});
