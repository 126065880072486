import { useUpdateSiteVisited as useUpdateSiteVisitedRequest } from '../../service/requests';
import {
  type Response,
  isFailure,
  isSuccess,
  loading,
  success,
  failure,
} from '../../service/response';
export const useUpdateSiteVisited = (
  siteName: string,
  subSiteName: string,
  visited: boolean,
): any => {
  const { updateSiteVisited } = useUpdateSiteVisitedRequest();
  const updateSite = async (): Promise<Response<any, Error>> => {
    const resp = await updateSiteVisited(
      encodeURI(siteName),
      encodeURI(subSiteName),
      visited,
    );

    if (isFailure(resp)) {
      return failure(resp.error as Error);
    }

    if (isSuccess(resp)) {
      return success(null);
    }

    return loading();
  };
  return {
    updateSite,
  };
};
