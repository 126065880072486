import { type Dispatch, type SetStateAction, useContext } from 'react';
import { useDeleteUserPlaylist as useDeleteUserPlaylistRequest } from '../../../service/requests';
import { UserContext } from '../../app/App';
import {
  isFailure,
  isSuccess,
  loading,
  success,
  type Response,
} from '../../../service/response';
import { type UserPlaylist } from './config';

export const useDeleteUserPlaylist = (
  setUserPlaylists: Dispatch<SetStateAction<UserPlaylist[]>>,
): any => {
  const { user } = useContext(UserContext);

  const {
    deleteUserPlaylist,
  }: {
    deleteUserPlaylist: (playlistId: string) => Promise<Response<null, Error>>;
  } = useDeleteUserPlaylistRequest({
    userId: user?.userId ?? '',
  });

  const deletePlaylist = async (
    playlistId: string,
  ): Promise<Response<null, Error>> => {
    if (!user || !playlistId) {
      return success(null);
    }

    const resp = await deleteUserPlaylist(playlistId);

    if (!isFailure(resp) && !isSuccess(resp)) {
      return loading();
    }

    if (isSuccess(resp)) {
      setUserPlaylists((playlists) =>
        playlists.filter((playlist) => playlist.playlistId !== playlistId),
      );
    }
    return resp;
  };

  return { deletePlaylist };
};
