import React from 'react';
import { Box } from '@chakra-ui/react';
import { StateView } from '../common/StateView';
import { useListRecords } from './useListRecords';
import { Carousel } from './Carousel';

export const Records: React.FC = StateView(useListRecords, ({ records }) => {
  return (
    <Box>
      <Carousel records={records} />
    </Box>
  );
});
