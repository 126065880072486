import { useNavigate } from 'react-router';
import { useCreatePhoto } from '../../../service/requests';
import {
  type Response,
  isFailure,
  isSuccess,
  loading,
} from '../../../service/response';
import { toUrl, createRoute } from '../../../util/routes';

interface UploadPhotoProps {
  location: string;
  country: string;
  takenAt: string;
  image: string;
}

export const useUploadPhoto = ({
  location,
  country,
  takenAt,
  image,
}: UploadPhotoProps): any => {
  const navigate = useNavigate();
  const uploadPhoto = async (): Promise<Response<any, any>> => {
    const { createPhoto } = useCreatePhoto({
      location,
      country,
      takenAt,
      image,
    });

    const resp = await createPhoto();

    if (!isFailure(resp) && !isSuccess(resp)) {
      return loading();
    }

    if (isSuccess(resp)) {
      navigate(toUrl(createRoute('photography', {})));
    }

    return resp;
  };
  return {
    uploadPhoto,
  };
};
