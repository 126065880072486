import React, { useState } from 'react';
import {
  Container,
  Input,
  Button,
  Box,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { toUrl, createRoute } from '../../../util/routes';
import { BreadcrumbGroup } from '../../common/breadcrumbs';
import { useListUserPlaylists } from './useListUserPlaylists';
import { StateView } from '../../common/StateView';
import { useCreateUserPlaylist } from './useCreateUserPlaylist';
import { useDeleteUserPlaylist } from './useDeleteUserPlaylist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export const Home: React.FC = StateView(
  useListUserPlaylists,
  ({ userPlaylists, setUserPlaylists }) => {
    const navigate = useNavigate();
    const [listenPlaylistId, setListenPlaylistId] = useState<string>('');
    const [addUserPlaylist, setAddUserPlaylist] = useState<string>('');

    const { createPlaylist } = useCreateUserPlaylist(
      addUserPlaylist,
      setUserPlaylists,
      setAddUserPlaylist,
    );

    const { deletePlaylist } = useDeleteUserPlaylist(setUserPlaylists);

    return (
      <Box>
        <BreadcrumbGroup breadcrumbPaths={['home', 'youtubeRandomizerHome']} />
        <Container centerContent>
          <Input
            onChange={(e) => {
              setListenPlaylistId(e.target.value);
            }}
            placeholder="Playlist ID"
          />
          <Button
            onClick={() => {
              navigate(
                toUrl(
                  createRoute('youtubeRandomizer', {
                    playlistId: extractPlaylistId(listenPlaylistId),
                  }),
                ),
              );
            }}
          >
            Listen!
          </Button>

          <Table size="sm" display={userPlaylists.length ? 'table' : 'none'}>
            <Thead>
              <Tr>
                <Th>Playlist Name</Th>
                <Th>Playlist ID</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {userPlaylists?.map((playlist) => {
                return (
                  <Tr key={playlist.playlistId}>
                    <Td>
                      <Text>{playlist.playlistName}</Text>
                    </Td>
                    <Td>
                      <Link
                        onClick={() => {
                          navigate(
                            toUrl(
                              createRoute('youtubeRandomizer', {
                                playlistId: playlist.playlistId,
                              }),
                            ),
                          );
                        }}
                      >
                        {playlist.playlistId}
                      </Link>
                    </Td>
                    <Td>
                      <FontAwesomeIcon
                        onClick={() => deletePlaylist(playlist.playlistId)}
                        key={playlist.playlistId}
                        size="lg"
                        icon={faTrash}
                        color="#ff575c"
                        style={{ cursor: 'pointer' }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Container>
            <Input
              onChange={(e) => {
                setAddUserPlaylist(e.target.value);
              }}
              placeholder="Playlist ID"
              value={addUserPlaylist}
            />
            <Button
              onClick={createPlaylist}
              disabled={
                userPlaylists.find((playlist) => {
                  return playlist.playlistId === addUserPlaylist;
                }) !== undefined
              }
            >
              Add
            </Button>
          </Container>
        </Container>
      </Box>
    );
  },
);

const extractPlaylistId = (input: string): string => {
  if (input.includes('youtube.com')) {
    return input.split('=')[1];
  }
  return input;
};
