import React from 'react';
import { StateView } from '../common/StateView';
import { useListPhotos } from './useListPhotos';
import { PhotoGallery } from './PhotoGallery';
import { BreadcrumbGroup } from '../common/breadcrumbs';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { PhotoMap } from './PhotoMap';

export const Photography: React.FC = StateView(
  useListPhotos,
  ({ photoResult }) => {
    return (
      <>
        <BreadcrumbGroup breadcrumbPaths={['home', 'photography']} />
        <Tabs variant="soft-rounded">
          <TabList>
            <Tab>Gallery</Tab>
            <Tab>Map</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PhotoGallery photos={photoResult} />
            </TabPanel>
            <TabPanel>
              <PhotoMap photos={photoResult} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  },
);
