import axios, { type AxiosRequestHeaders, type AxiosResponse } from 'axios';

export const useAxios = (): any => {
  const get = async (uri: string): Promise<AxiosResponse<any, Error>> => {
    return await axios.get(uri);
  };

  const post = async (
    uri: string,
    payload: any,
    headers?: AxiosRequestHeaders,
  ): Promise<AxiosResponse<any, Error>> => {
    if (headers) {
      return await axios.post(uri, payload, { headers });
    }
    return await axios.post(uri, payload);
  };

  const del = async (
    uri: string,
    payload: any,
  ): Promise<AxiosResponse<any, Error>> => {
    return await axios.delete(uri, payload);
  };

  return {
    get,
    post,
    del,
  };
};
