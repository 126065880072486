import React, { useContext, useEffect } from 'react';
import { StateView } from '../common/StateView';
import { useAuthenticate } from './useAuthenticate';
import { ID_TOKEN, REFRESH_TOKEN, USER_ID } from '../../util/cognito';
import { UserContext } from '../app/App';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../util/routes';
import jwt_decode from 'jwt-decode';
import { type AuthenticateOutput, type Map } from '../../util/types';

export const Authenticate = StateView(
  useAuthenticate,
  ({ data }: { data: AuthenticateOutput }) => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (!data?.refreshToken) {
        return;
      }

      const userId = jwt_decode<Map>(data.idToken)['cognito:username'];

      localStorage.setItem(ID_TOKEN, data.idToken);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
      localStorage.setItem(USER_ID, userId);

      setUser({
        idToken: data.idToken,
        refreshToken: data.refreshToken,
        userId,
      });

      navigate(paths.home);
    }, []);

    return <></>;
  },
);
