import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Input, Text } from '@chakra-ui/react';
import { ImageUpload } from '../../common/imageUpload';

import { useUploadPhoto } from './useUploadPhoto';
import { useNavigate } from 'react-router';
import { toUrl, createRoute } from '../../../util/routes';
import { UserContext } from '../../app/App';

export const UploadPhoto = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(toUrl(createRoute('photography', {})));
    }
  }, [user]);

  const [location, setLocation] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [takenAt, setTakenAt] = useState<string>('');
  const [image, setImage] = useState<string>('');

  const { uploadPhoto } = useUploadPhoto({
    location,
    country,
    takenAt,
    image,
  });
  return (
    <Box>
      <Container centerContent>
        <Text size="lg" fontWeight="black">
          Upload Photo
        </Text>
        <Input
          value={location}
          placeholder="Location"
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
        <Input
          value={country}
          placeholder="Country"
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
        <Input
          value={takenAt}
          placeholder="Taken"
          onChange={(e) => {
            setTakenAt(e.target.value);
          }}
        />

        <ImageUpload setImage={setImage} />
        <Button onClick={uploadPhoto}>Submit</Button>
      </Container>
    </Box>
  );
};
