import { useListRecords as useListRecordsRequest } from '../../service/requests';
import { isSuccess, success, type Response } from '../../service/response';
import { toRecord, type Record } from './config';

interface Result {
  records: Record[];
}

export const useListRecords = (): Response<Result, Error> => {
  const resp = useListRecordsRequest({ username: 'caseyben' });
  if (isSuccess(resp)) {
    return success({ records: resp.data.map(toRecord) });
  }

  return resp;
};
