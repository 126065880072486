import React, { useEffect, useState } from 'react';
import { IMAGE_URI } from '../../service/constants';
import { Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

interface PhotoMapImageProps {
  photos: string[];
}

export const PhotoMapImage = ({ photos }: PhotoMapImageProps): JSX.Element => {
  const [currentPhoto, setCurrentPhoto] = useState<number>(0);
  const handleArrowClick = (dir: number): void => {
    setCurrentPhoto((prevState: number) => prevState + dir);
  };

  useEffect(() => {
    setCurrentPhoto(0);
  }, [photos]);

  return (
    <>
      <Image
        width={400}
        height="auto"
        style={{ textAlign: 'center', userSelect: 'none' }}
        src={`${IMAGE_URI}/${photos[currentPhoto]}`}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleArrowClick(-1);
        }}
        icon={faAngleLeft}
        size="3x"
        style={{
          visibility:
            photos[currentPhoto] !== photos[0] && photos.length > 1
              ? 'visible'
              : 'hidden',
        }}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleArrowClick(1);
        }}
        icon={faAngleRight}
        size="3x"
        style={{
          visibility:
            photos[currentPhoto] !== photos[photos.length - 1] &&
            photos.length > 1
              ? 'visible'
              : 'hidden',
        }}
      />
    </>
  );
};
