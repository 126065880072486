import React from 'react';
import { GenreCategory, type SongSummary } from '../types';
import { Badge, Box, Divider, Image, Text, VStack } from '@chakra-ui/react';
import { IMAGE_URI } from '../../../../service/constants';
import { Card, CardBody, CardFooter, CardHeader } from '@chakra-ui/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDeleteSong } from './useDeleteSong';

interface SongDisplayProps {
  song: SongSummary;
  isAuthenticated: boolean;
  setSongs: React.Dispatch<React.SetStateAction<SongSummary[]>>;
}

export const SongDisplay = ({
  song,
  isAuthenticated,
  setSongs,
}: SongDisplayProps): JSX.Element => {
  const { deleteSong } = useDeleteSong(song.songId, setSongs);
  return (
    <Card
      paddingLeft="10px"
      paddingRight="10px"
      backgroundColor="gray.50"
      borderColor="gray.100"
      borderWidth="2px"
      borderRadius="xl"
    >
      <CardHeader padding={0}>
        <Badge borderRadius={6} colorScheme={`genre.${song.genreCategory}`}>
          {GenreCategory[song.genreCategory as keyof typeof GenreCategory]}
        </Badge>
        <FontAwesomeIcon
          onClick={deleteSong}
          size="sm"
          icon={faTrash}
          color="#ff575c"
          style={{
            cursor: 'pointer',
            float: 'right',
            marginTop: '5px',
            display: isAuthenticated ? 'block' : 'none',
          }}
        />
      </CardHeader>
      <CardBody>
        <Box>
          <Image src={`${IMAGE_URI}/${song.imageId}`} />
        </Box>
        <VStack spacing={-2}>
          <Text textAlign="center" fontWeight="semibold" fontSize="3xl">
            {song.songName}
          </Text>
          <Text textAlign="center" fontSize="xl">
            {song.artistName}
          </Text>
        </VStack>
        <Text>{song.shortDescription}</Text>
      </CardBody>
      <Divider marginTop="10px" />
      <CardFooter padding={2}>
        <Text fontSize="xs" as="i" color="gray.500">
          {song.genres.join(', ')}
        </Text>
      </CardFooter>
    </Card>
  );
};
