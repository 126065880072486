export interface Record {
  id: string;
  artistName: string;
  albumName: string;
  imageUrl: string;
}

export interface CarouselInput {
  records: Record[];
}

export interface CardInput {
  data: Record[];
  dataIndex: number;
}

export const toRecord = (response: any): Record => {
  return {
    id: response.instance_id,
    artistName: response.basic_information.artists[0].name,
    albumName: response.basic_information.title,
    imageUrl: response.basic_information.cover_image,
  };
};
