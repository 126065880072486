import { useListPhotos as listPhotos } from '../../service/requests';
import { isSuccess, success, type Response } from '../../service/response';
import { type ListPhotosOutput } from '../../util/types';
import { type Photo } from './util';

interface Result {
  photoResult: Photo[];
}

const toPhoto = (input: ListPhotosOutput[number]): Photo => {
  return {
    photoId: input.photoId,
    location: input.location,
    country: input.country,
    takenAt: input.takenAt,
    imageId: input.imageId,
  };
};

export const useListPhotos = (): Response<Result, Error> => {
  const resp = listPhotos();

  if (isSuccess(resp)) {
    return success({ photoResult: resp.data.map(toPhoto) });
  }

  return resp;
};
