import { DEV_URL, DOMAIN, devOrProd } from './env';

export const CLIENT_ID = '1snng3t9nrkgsct9fdd9b4g1n';
export const createLoginUrl = `https://auth.${DOMAIN}/login?client_id=${CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${devOrProd(
  `http://${DEV_URL}`,
  `https://${DOMAIN}`,
)}/authenticate`;

export const ID_TOKEN = 'idToken';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_ID = 'userId';
