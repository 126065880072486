import {
  BreadcrumbLink,
  type BreadcrumbLinkProps,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { type RouteName } from '../../../util/routes';

export interface InternalLinkProps {
  to: RouteName;
  children: React.ReactNode;
}

export const InternalLink: React.FC<InternalLinkProps> = ({ to, children }) => {
  return (
    <Link as={RouterLink} to={to}>
      {children}
    </Link>
  );
};

export interface InternalBreadcrumbLinkProps {
  to: RouteName;
  children: React.ReactNode;
  props?: BreadcrumbLinkProps;
}

export const InternalBreadcrumbLink: React.FC<InternalBreadcrumbLinkProps> = ({
  to,
  children,
  props,
}) => {
  return (
    <BreadcrumbLink color="blue.500" as={RouterLink} to={to} {...props}>
      {children}
    </BreadcrumbLink>
  );
};

export interface ExternalLinkProps {
  to: string;
  children: React.ReactNode;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ to, children }) => {
  return (
    <Link as={RouterLink} to={to}>
      {children}
    </Link>
  );
};
