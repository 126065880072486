import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthenticateCognito } from '../../service/requests';
import {
  isSuccess,
  type Response,
  success,
  failure,
} from '../../service/response';
import { paths } from '../../util/routes';

export const useAuthenticate = (): Response<any, Error> => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  if (!code) {
    navigate(paths.home);
    return failure(new Error('No auth code presented'));
  }

  const resp = useAuthenticateCognito({ code });

  if (isSuccess(resp)) {
    return success(resp);
  }

  return resp;
};
