import { type AxiosResponse } from 'axios';
import { API_URI } from './constants';
import { deleteRequest, getRequest, postRequest } from './requestUtils';

export const apiGet = async <D>(
  path: string,
  ...queryStrings: string[]
): Promise<AxiosResponse<D, Error>> => {
  return await getRequest(
    `${API_URI}${path}?${buildQueryStrings(queryStrings)}`,
  );
};

export const apiPost = async <D>(
  path: string,
  body: Record<string, string>,
  ...queryStrings: string[]
): Promise<AxiosResponse<D, Error>> => {
  return await postRequest(
    `${API_URI}${path}?${buildQueryStrings(queryStrings)}`,
    body,
  );
};

export const apiDelete = async <D>(
  path: string,
  ...queryStrings: string[]
): Promise<AxiosResponse<D, Error>> => {
  return await deleteRequest(
    `${API_URI}${path}?${buildQueryStrings(queryStrings)}`,
  );
};

const buildQueryStrings = (queryStrings: string[]): string => {
  return queryStrings.join('&');
};
