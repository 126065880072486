import { useListSongs as listSongs } from '../../../service/requests';
import { isSuccess, success, type Response } from '../../../service/response';
import { type ListSongsOutput } from '../../../util/types';
import { type SongSummary } from '../common/types';

interface Result {
  songSummaries: SongSummary[];
}

const toSongSummary = (input: ListSongsOutput[number]): SongSummary => {
  return {
    songId: input.songId,
    artistName: input.artistName,
    songName: input.songName,
    shortDescription: input.shortDescription,
    imageId: input.imageId,
    genres: input.genres,
    genreCategory: input.genreCategory,
  };
};

export const useListSongs = (): Response<Result, Error> => {
  const resp = listSongs();

  if (isSuccess(resp)) {
    return success({ songSummaries: resp.data.map(toSongSummary) });
  }

  return resp;
};
