import React, { useContext } from 'react';
import { Box, Button, Center, Image, SimpleGrid } from '@chakra-ui/react';
import { StateView } from '../common/StateView';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../app/App';
import { paths } from '../../util/routes';
import { BreadcrumbGroup } from '../common/breadcrumbs';
import { useListPhotos } from './useListPhotos';
import { IMAGE_URI } from '../../service/constants';

export const Photography: React.FC = StateView(
  useListPhotos,
  ({ photoResult }) => {
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    return (
      <Box>
        <BreadcrumbGroup breadcrumbPaths={['home', 'photography']} />
        <Button
          style={{ display: user ? 'block' : 'none' }}
          onClick={() => {
            if (user) {
              navigate(paths.uploadPhoto);
            }
          }}
        >
          Create
        </Button>
        <Center>
          <SimpleGrid minChildWidth="300px" spacing="70px">
            {photoResult.map((photo) => {
              return (
                <Image
                  key={photo.photoId}
                  src={`${IMAGE_URI}/${photo.imageId}`}
                />
              );
            })}
          </SimpleGrid>
        </Center>
      </Box>
    );
  },
);
