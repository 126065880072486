export const API_KEYS = {
  getYoutubePlaylist: 'getYoutubePlaylist',
  authenticateCognito: 'authenticateCognito',
  refreshCognito: 'refreshCognito',
  listUserPlaylists: 'listUserPlaylists',
  listSongs: 'listSongs',
  getSong: 'getSong',
  getImage: 'getImage',
  listRecords: 'listRecords',
  listPhotos: 'listPhotos',
};

export const API_URI = 'https://caseysmith.ca/prod';
export const IMAGE_URI = 'https://caseysmith.ca/images';
