import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { type Photo } from '../../util/types';
import React, { useMemo } from 'react';
import {
  devOrProd,
  GOOGLE_MAPS_DEV_API_KEY,
  GOOGLE_MAPS_PROD_API_KEY,
} from '../../util/env';
import { Spinner } from '@chakra-ui/react';
import { PhotoMapImage } from './PhotoMapImage';
import { UnescoLogo } from '../common/svg/UnescoLogo';

interface PhotoMapProps {
  photos: Photo[];
}

const DEFAULT_ZOOM = 2;
const center = {
  lat: 3.745,
  lng: 38.523,
};
const containerStyle = {
  width: 'auto',
  height: '700px',
};

const isUnescoSite = (photo?: Photo): boolean => {
  return !!photo?.siteName;
};

export const PhotoMap = ({ photos }: PhotoMapProps): JSX.Element => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const [selectedPhoto, setSelectedPhoto] = React.useState<Photo | null>();
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const photoMap = useMemo(() => {
    const out: Record<string, string[]> = {};

    photos.forEach((photo: Photo) => {
      const key = `${photo.latitude}-${photo.longitude}`;
      if (out[key]) {
        out[key].push(photo.photoId);
      } else {
        out[key] = [photo.photoId];
      }
    });
    return out;
  }, [photos]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-unesco',
    googleMapsApiKey: devOrProd(
      GOOGLE_MAPS_DEV_API_KEY,
      GOOGLE_MAPS_PROD_API_KEY,
    ),
  });

  const handlePhotoClick = (site: Photo): void => {
    setSelectedPhoto(site);
    map?.panTo({ lat: site.latitude, lng: site.longitude });
  };

  return isLoaded ? (
    <GoogleMap
      center={center}
      mapContainerStyle={containerStyle}
      zoom={DEFAULT_ZOOM}
      onLoad={onLoad}
    >
      {photos.map((photo: Photo, index: number) => {
        return (
          <MarkerF
            options={{
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 3,
                strokeColor: '#000000',
                strokeOpacity: 1,
                fillOpacity: 1,
                fillColor: '#000000',
              },
            }}
            title={
              isUnescoSite(photo)
                ? `${photo.siteName} - ${photo.subSiteName}`
                : photo.locationName
            }
            key={index}
            onClick={() => {
              handlePhotoClick(photo);
            }}
            position={{ lat: photo.latitude, lng: photo.longitude }}
          />
        );
      })}
      {selectedPhoto && (
        <InfoWindowF
          position={
            new google.maps.LatLng(
              selectedPhoto?.latitude ?? 0,
              selectedPhoto?.longitude ?? 0,
            )
          }
          onCloseClick={() => {
            setSelectedPhoto(null);
          }}
          options={{
            headerContent: isUnescoSite(selectedPhoto)
              ? selectedPhoto?.siteName
              : selectedPhoto?.locationName,
          }}
        >
          <>
            {isUnescoSite(selectedPhoto) && (
              <div style={{ float: 'right' }}>
                <UnescoLogo width={25} height={25} />
              </div>
            )}
            {isUnescoSite(selectedPhoto) && selectedPhoto?.subSiteName}
            {isUnescoSite(selectedPhoto) && <br />}
            {selectedPhoto?.country}
            <br />
            <PhotoMapImage
              photos={
                photoMap[`${selectedPhoto.latitude}-${selectedPhoto.longitude}`]
              }
            />
          </>
        </InfoWindowF>
      )}
    </GoogleMap>
  ) : (
    <Spinner />
  );
};
