import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  MarkerClustererF,
  InfoWindowF,
} from '@react-google-maps/api';
import {
  GOOGLE_MAPS_DEV_API_KEY,
  GOOGLE_MAPS_PROD_API_KEY,
  devOrProd,
} from '../../util/env';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import { useListUnescoSites } from './useListUnescoSites';
import { type UnescoSite } from '../../util/types';
import {
  type ClusterIconInfo,
  type ClusterIconStyle,
  type ClustererOptions,
  type MarkerExtended,
} from '@react-google-maps/marker-clusterer';
import { UserContext } from '../app/App';
import { StateView } from '../common/StateView';
import { useUpdateSiteVisited } from './useUpdateSiteVisited';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryString } from '../../util/routes';

enum Filters {
  COUNTRY = 'country',
  HIDDEN = 'hidden',
}

const center = {
  lat: 3.745,
  lng: 38.523,
};
const containerStyle = {
  width: 'auto',
  height: '700px',
};

const baseClusterIconStyle: Partial<ClusterIconStyle> = {
  textColor: '#D40511',
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight: '700',
};

const smallClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iMjYiIGN5PSIyNiIgcj0iMTYiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSIyNiIgY3k9IjI2IiByPSIxNS4yNSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvZz48L3N2Zz4=',
  height: 52,
  width: 52,
  textSize: 16,
};

const mediumClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iMzQiIGN5PSIzNCIgcj0iMjQiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSIzNCIgY3k9IjM0IiByPSIyMyIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+',
  height: 68,
  width: 68,
  textSize: 18,
};

const largeClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iOTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iNDUiIGN5PSI0NSIgcj0iMzUiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSI0NSIgY3k9IjQ1IiByPSIzMy41IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMyIvPjwvZz48L3N2Zz4=',
  height: 90,
  width: 90,
  textSize: 22,
};

const clusterIconStyles = [
  smallClusterIconStyle,
  mediumClusterIconStyle,
  largeClusterIconStyle,
];

const markerLengthToIndex = (length: number): number => {
  if (length >= 50) {
    return 3;
  } else if (length >= 10) {
    return 2;
  }
  return 1;
};

const markerClustererCalculator = (
  markers: MarkerExtended[],
): ClusterIconInfo => ({
  text: `${markers.length}`,
  index: markerLengthToIndex(markers.length),
  title: '',
});

const markerClustererOptions: ClustererOptions = {
  averageCenter: true,
  calculator: markerClustererCalculator,
  maxZoom: 5,
  styles: clusterIconStyles,
  ignoreHidden: true,
};

const DEFAULT_ZOOM = 2;

export const Unesco: React.FC = StateView(useListUnescoSites, ({ sites }) => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const [selectedSite, setSelectedSite] = React.useState<UnescoSite | null>();
  const { user } = useContext(UserContext);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState<string>(
    sites[0].siteName,
  );
  const [selectedSubSiteVisit, setSelectedSubSiteVisit] = useState<string>(
    sites[0].subSiteName,
  );
  const [visitedCheck, setVisitedCheck] = useState<boolean>(
    sites[0].visited ?? false,
  );
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [hiddenSites, setHiddenSites] = useState<string[]>([]);

  const { updateSite } = useUpdateSiteVisited(
    selectedSiteVisit,
    selectedSubSiteVisit,
    visitedCheck,
  );

  const qs = useQueryString();

  const [onlyShowVisited, setOnlyShowVisited] = useState<boolean>(
    qs.get('visited') === 'true',
  );

  const search = useMemo(() => qs.get('search'), [qs]);

  useEffect(() => {
    setSelectedSubSiteVisit(
      sites.find((site: UnescoSite) => site.siteName === selectedSiteVisit)!
        .subSiteName,
    );
  }, [selectedSiteVisit]);

  useEffect(() => {
    if (search) {
      const decodedSearch = atob(search);
      decodedSearch.split('|').forEach((filterSearch) => {
        if (filterSearch === '') {
          return;
        }
        const filter = filterSearch.split('=')[0];
        const items = filterSearch.split('=')[1].split(',');

        switch (filter) {
          case Filters.COUNTRY:
            setSelectedCountries(
              items
                .map((item) => decodeURIComponent(item))
                .filter((item) => countries.includes(item)),
            );
            break;
          case Filters.HIDDEN:
            setHiddenSites(
              items
                .map((item) => decodeURIComponent(item))
                .filter((item) => uniqueSites.includes(item)),
            );
            break;
          default:
            break;
        }
      });
    }
  }, [search]);

  const handleSiteClick = (site: UnescoSite): void => {
    setSelectedSite(site);
    map?.panTo({ lat: site.latitude, lng: site.longitude });
  };

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-unesco',
    googleMapsApiKey: devOrProd(
      GOOGLE_MAPS_DEV_API_KEY,
      GOOGLE_MAPS_PROD_API_KEY,
    ),
  });

  const uniqueSites = useMemo(() => {
    return Array.from(
      new Set(sites.map((site: UnescoSite) => site.siteName)),
    ).sort();
  }, [sites]);

  const markerColours = useMemo(() => {
    const map: Record<string, string> = {};

    sites.forEach((site: UnescoSite) => {
      if (!map[site.unescoId]) {
        map[site.unescoId] =
          '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
      }
    });

    return map;
  }, []);

  const visitedSites = useMemo(() => {
    return sites.filter((site: UnescoSite) => site.visited);
  }, []);

  const countries = useMemo(() => {
    return Array.from(
      new Set(sites.map((site: UnescoSite) => site.country)),
    ).sort();
  }, []);

  const handleVisitedOnlyClick = (checked: boolean): void => {
    setOnlyShowVisited(checked);
    map?.setZoom(DEFAULT_ZOOM);
  };

  useEffect(() => {
    map?.setZoom(DEFAULT_ZOOM);
  }, [selectedCountries]);

  return isLoaded ? (
    <>
      <SimpleGrid columns={4}>
        <Checkbox
          isChecked={onlyShowVisited}
          checked={onlyShowVisited}
          onChange={(e) => {
            handleVisitedOnlyClick(e.target.checked);
          }}
        >
          Only show visited?
        </Checkbox>
        <div>
          Sites visited:{' '}
          {new Set(visitedSites.map((site: UnescoSite) => site.unescoId)).size}
        </div>

        <SimpleGrid columns={3}>
          <Popover>
            <PopoverTrigger>
              <Button>Filter Countries</Button>
            </PopoverTrigger>
            <PopoverContent overflow="scroll" maxHeight={200}>
              <CheckboxGroup
                value={selectedCountries}
                onChange={(e) => {
                  setSelectedCountries(e as string[]);
                }}
              >
                {countries.map((country: string) => (
                  <Checkbox value={country} key={country}>
                    {country}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </PopoverContent>
          </Popover>
          <div style={{ display: hiddenSites.length > 0 ? 'block' : 'none' }}>
            <Popover>
              <PopoverTrigger>
                <Button>Hidden Sites</Button>
              </PopoverTrigger>
              <PopoverContent overflow="scroll" maxHeight={200}>
                {hiddenSites.map((hiddenSite: string) => (
                  <div key={`${hiddenSite}-remove`}>
                    <IconButton
                      onClick={() => {
                        setHiddenSites((prevState: string[]) =>
                          prevState.filter((site) => site !== hiddenSite),
                        );
                      }}
                      variant="ghost"
                      icon={<FontAwesomeIcon icon={faBackspace} />}
                      aria-label="delete"
                    />
                    {hiddenSite}
                  </div>
                ))}
              </PopoverContent>
            </Popover>
          </div>
          <div
            style={{
              display:
                hiddenSites.length > 0 || selectedCountries.length > 0
                  ? 'block'
                  : 'none',
            }}
          >
            <Button
              onClick={() => {
                setSelectedCountries([]);
                setHiddenSites([]);
              }}
            >
              Clear Filters
            </Button>

            <Button
              onClick={() => {
                let queryString = '';

                if (selectedCountries.length > 0) {
                  queryString += `${Filters.COUNTRY}=${selectedCountries
                    .map((country) => encodeURIComponent(country))
                    .toString()}|`;
                }

                if (hiddenSites.length > 0) {
                  queryString += `${Filters.HIDDEN}=${hiddenSites
                    .map((site) => encodeURIComponent(site))
                    .toString()}|`;
                }

                queryString = `?search=${btoa(queryString)}`;

                queryString += `&visited=${onlyShowVisited}`;

                navigator.clipboard
                  .writeText(
                    `${window.location.href.split('?')[0]}${queryString}`,
                  )
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              Copy Search
            </Button>
          </div>
        </SimpleGrid>
      </SimpleGrid>
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        zoom={DEFAULT_ZOOM}
        onLoad={onLoad}
      >
        {sites.length > 0 && (
          <MarkerClustererF options={markerClustererOptions}>
            {(clusterer) => (
              <>
                {sites.map((site: UnescoSite, index: number) => {
                  return (
                    <MarkerF
                      visible={
                        !hiddenSites.includes(site.siteName) &&
                        (!selectedCountries.length ||
                          (!!selectedCountries.length &&
                            selectedCountries?.includes(site.country))) &&
                        (!onlyShowVisited ||
                          (onlyShowVisited && site.visited === true))
                      }
                      clusterer={clusterer}
                      options={{
                        icon: {
                          path: google.maps.SymbolPath.CIRCLE,
                          scale: 3,
                          strokeColor: markerColours[site.unescoId],
                          strokeOpacity: 1,
                          fillOpacity: 1,
                          fillColor: markerColours[site.unescoId],
                        },
                      }}
                      title={`${site.siteName} - ${site.subSiteName}`}
                      key={index}
                      onClick={() => {
                        handleSiteClick(site);
                      }}
                      position={{ lat: site.latitude, lng: site.longitude }}
                    />
                  );
                })}
              </>
            )}
          </MarkerClustererF>
        )}
        {selectedSite && (
          <InfoWindowF
            position={
              new google.maps.LatLng(
                selectedSite?.latitude ?? 0,
                selectedSite?.longitude ?? 0,
              )
            }
            onCloseClick={() => {
              setSelectedSite(null);
            }}
            options={{ headerContent: selectedSite?.siteName }}
          >
            <>
              {selectedSite?.subSiteName}
              <br />
              {selectedSite?.country}
              <br />
              <Button
                onClick={() => {
                  setHiddenSites((prevState: string[]) => [
                    ...prevState,
                    selectedSite.siteName,
                  ]);
                  setSelectedSite(null);
                }}
              >
                Hide
              </Button>
            </>
          </InfoWindowF>
        )}
      </GoogleMap>
      {user && (
        <>
          <Select
            value={selectedSiteVisit}
            onChange={(e) => {
              setSelectedSiteVisit(e.target.value);
            }}
          >
            {uniqueSites.map((site) => {
              return (
                <option key={site} value={site}>
                  {site}
                </option>
              );
            })}
          </Select>
          {selectedSiteVisit && (
            <Select
              value={selectedSubSiteVisit}
              onChange={(e) => {
                setSelectedSubSiteVisit(e.target.value);
              }}
            >
              {sites
                .filter(
                  (site: UnescoSite) => site.siteName === selectedSiteVisit,
                )
                .map(({ subSiteName }: UnescoSite) => {
                  return (
                    <option key={subSiteName} value={subSiteName}>
                      {subSiteName}
                    </option>
                  );
                })}
            </Select>
          )}
          <Checkbox
            checked={visitedCheck}
            onChange={(e) => {
              setVisitedCheck(e.target.checked);
            }}
          >
            Visited?
          </Checkbox>
          <Button onClick={updateSite}>Update</Button>
        </>
      )}
    </>
  ) : (
    <></>
  );
});
