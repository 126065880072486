import React from 'react';
import { BreadcrumbGroup } from '../../common/breadcrumbs';
import { Box, Button } from '@chakra-ui/react';
import { createRoute, toUrl } from '../../../util/routes';
import { useNavigate } from 'react-router-dom';

export const Projects = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box>
      <BreadcrumbGroup breadcrumbPaths={['home', 'projects']} />
      <Button
        onClick={() => {
          navigate(toUrl(createRoute('youtubeRandomizerHome')));
        }}
      >
        Youtube Randomizer
      </Button>
      <Button
        onClick={() => {
          navigate(toUrl(createRoute('cscsHome')));
        }}
      >
        Casey Smiths Curated Songs
      </Button>
      <Button
        style={{ display: 'none' }}
        onClick={() => {
          navigate(toUrl(createRoute('nutrition')));
        }}
      >
        Nutrition Repository
      </Button>
      <Button
        onClick={() => {
          navigate(toUrl(createRoute('records')));
        }}
      >
        Records
      </Button>
    </Box>
  );
};
