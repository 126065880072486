import React from 'react';
import {
  StackedCarousel,
  ResponsiveContainer,
} from 'react-stacked-center-carousel';
import { Image, Box, Button } from '@chakra-ui/react';
import { type CarouselInput, type CardInput } from './config';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

export const Card = React.memo(
  ({ data, dataIndex }: CardInput): JSX.Element => {
    const { imageUrl, albumName, artistName } = data[dataIndex];
    return (
      <Box
        style={{
          width: window.innerWidth < 720 ? 300 : 600,
          height: window.innerWidth < 720 ? 300 : 600,
          userSelect: 'none',
        }}
      >
        <Image
          style={{
            height: '90%',
            width: '90%',
            objectFit: 'cover',
            borderRadius: 0,
          }}
          draggable={false}
          src={imageUrl}
        />
        <div>{`${artistName} - ${albumName}`}</div>
      </Box>
    );
  },
);
Card.displayName = 'Card';

export const Carousel = ({ records }: CarouselInput): JSX.Element => {
  const ref = React.useRef();
  return (
    <Box style={{ width: '100%', position: 'relative' }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={records}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <Button
          style={{ position: 'absolute', top: '40%', left: 10, zIndex: 10 }}
          size="large"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowLeftIcon />
        </Button>
        <Button
          style={{ position: 'absolute', top: '40%', right: 10, zIndex: 10 }}
          size="large"
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowRightIcon />
        </Button>
      </>
    </Box>
  );
};
