import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserContext } from '../../app/App';
import { useListUserPlaylists as listUserPlaylists } from '../../../service/requests';
import {
  success,
  type Response,
  isSuccess,
  isLoading,
  isFailure,
} from '../../../service/response';
import { type ListPlaylistOutput } from '../../../util/types';
import { type UserPlaylist } from './config';

interface Result {
  userPlaylists: UserPlaylist[];
  setUserPlaylists: Dispatch<SetStateAction<UserPlaylist[]>>;
}

const toUserPlaylist = (input: ListPlaylistOutput[number]): UserPlaylist => {
  return {
    playlistId: input.playlistId,
    playlistName: input.playlistName,
  };
};

export const useListUserPlaylists = (): Response<Result, Error> => {
  const { user } = useContext(UserContext);

  if (!user) {
    return success({ userPlaylists: [], setUserPlaylists: () => {} });
  }

  const [userPlaylists, setUserPlaylists] = useState<UserPlaylist[]>([]);

  const resp = listUserPlaylists({ userId: user.userId });

  useEffect(() => {
    if (isSuccess(resp)) {
      setUserPlaylists(resp.data.map(toUserPlaylist));
    }
  }, [resp.state]);

  if (isLoading(resp) || isFailure(resp)) {
    return resp;
  }

  return success({
    userPlaylists,
    setUserPlaylists,
  });
};
