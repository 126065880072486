export interface Success<D> {
  state: 'success';
  data: D;
}
export interface Failure<E> {
  state: 'failure';
  error: E;
}
export interface Loading {
  state: 'loading';
}

export type Response<D, E> = Success<D> | Failure<E> | Loading;

export const success = <D>(data: D): Success<D> => ({
  state: 'success',
  data,
});

export const failure = <E>(error: E): Failure<E> => ({
  state: 'failure',
  error,
});

export const loading = (): Loading => ({
  state: 'loading',
});

export const isSuccess = <D>(r: Response<D, any>): r is Success<D> =>
  r.state === 'success';
export const isFailure = <E>(r: Response<any, E>): r is Failure<E> =>
  r.state === 'failure';
export const isLoading = (r: Response<any, any>): r is Loading =>
  r.state === 'loading';

interface Transforms<D, E, T> {
  fromSuccess: (data: D) => T;
  fromFailure: (failure: E) => T;
  fromLoading: () => T;
}

export const map = <D, E, T>(
  transform: Transforms<D, E, T>,
  r: Response<D, E>,
): T => {
  switch (r.state) {
    case 'success':
      return transform.fromSuccess(r.data);
    case 'failure':
      return transform.fromFailure(r.error);
    case 'loading':
      return transform.fromLoading();
  }
};

export const mapSuccess = <D1, D2, E>(
  transform: (data: D1) => D2,
  r: Response<D1, E>,
): Response<D2, E> =>
  r.state === 'success' ? { ...r, data: transform(r.data) } : r;
