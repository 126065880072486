import {
  isFailure,
  isSuccess,
  loading,
  type Response,
} from '../../../../service/response';
import { useCreateSong } from '../../../../service/requests';
import { useNavigate } from 'react-router-dom';
import { createRoute, toUrl } from '../../../../util/routes';
interface Input {
  artistName: string;
  songName: string;
  shortDescription: string;
  longDescription: string;
  image: string;
  genres: string;
  genreCategory: string;
}

export const useCreateEntry = ({
  artistName,
  songName,
  shortDescription,
  longDescription,
  image,
  genres,
  genreCategory,
}: Input): any => {
  const navigate = useNavigate();
  const { createSong }: { createSong: () => Promise<Response<null, Error>> } =
    useCreateSong({
      artistName,
      songName,
      shortDescription,
      longDescription,
      image,
      genres,
      genreCategory,
    });

  const createEntry = async (): Promise<Response<null, Error>> => {
    const resp = await createSong();

    if (!isFailure(resp) && !isSuccess(resp)) {
      return loading();
    }

    if (isSuccess(resp)) {
      navigate(toUrl(createRoute('cscsHome')));
      return resp;
    }

    return resp;
  };

  return { createEntry };
};
