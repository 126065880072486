import { type Dispatch, type SetStateAction, useContext } from 'react';
import { useCreateUserPlaylist as useCreateUserPlaylistRequest } from '../../../service/requests';
import { UserContext } from '../../app/App';
import {
  isFailure,
  isSuccess,
  loading,
  success,
  type Response,
} from '../../../service/response';
import { type UserPlaylist } from './config';
import { type CreatePlaylistOutput } from '../../../util/types';

export const useCreateUserPlaylist = (
  playlistId: string,
  setUserPlaylists: Dispatch<SetStateAction<UserPlaylist[]>>,
  setAddUserPlaylist: Dispatch<SetStateAction<string>>,
): any => {
  const { user } = useContext(UserContext);

  const {
    createUserPlaylist,
  }: {
    createUserPlaylist: () => Promise<Response<CreatePlaylistOutput, Error>>;
  } = useCreateUserPlaylistRequest({
    userId: user?.userId ?? '',
    playlistId,
  });

  const createPlaylist = async (): Promise<
    Response<CreatePlaylistOutput, Error>
  > => {
    if (!user || !playlistId) {
      return success({ playlistName: 'null' });
    }

    const resp = await createUserPlaylist();

    if (!isFailure(resp) && !isSuccess(resp)) {
      return loading();
    }

    if (isSuccess(resp)) {
      setUserPlaylists((playlists) => [
        ...playlists,
        { playlistId, playlistName: resp.data.playlistName },
      ]);
      setAddUserPlaylist('');
    }
    return resp;
  };

  return { createPlaylist };
};
