import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    genre: {
      Pop: {
        50: '#ffe5f2',
        100: '#f9bcd2',
        200: '#ee91b3',
        300: '#e56694',
        400: '#dd3c75',
        500: '#c3225c',
        600: '#991947',
        700: '#6f1033',
        800: '#44061f',
        900: '#1d000b',
      },
      Rock: {
        50: '#e5f9ec',
        100: '#c7e8d0',
        200: '#a6d8b3',
        300: '#84c896',
        400: '#61b879',
        500: '#499f60',
        600: '#377b4a',
        700: '#265834',
        800: '#14361e',
        900: '#001405',
      },
      Metal: {
        50: '#f9f1e9',
        100: '#ded9d2',
        200: '#c5bfb9',
        300: '#ada69f',
        400: '#958c84',
        500: '#7c736a',
        600: '#605952',
        700: '#46403a',
        800: '#2b2621',
        900: '#140c02',
      },
      Hiphop: {
        50: '#ffece6',
        100: '#edcdc7',
        200: '#daaea4',
        300: '#ca9082',
        400: '#b9705f',
        500: '#a05646',
        600: '#7d4335',
        700: '#5b2f26',
        800: '#381b15',
        900: '#1b0600',
      },
      Electronic: {
        50: '#f6ffe5',
        100: '#e5feb8',
        200: '#d6fe88',
        300: '#c5fe59',
        400: '#b4fe33',
        500: '#9be525',
        600: '#78b21b',
        700: '#567f12',
        800: '#344d07',
        900: '#111a00',
      },
      Experimental: {
        50: '#fffbdc',
        100: '#fef2b1',
        200: '#fbea83',
        300: '#f9e152',
        400: '#f8d924',
        500: '#debf0e',
        600: '#ad9506',
        700: '#7b6a01',
        800: '#4a4000',
        900: '#1b1500',
      },
      Classical: {
        50: '#e6f5ff',
        100: '#c2dff0',
        200: '#9ccae4',
        300: '#76b4d7',
        400: '#539fcc',
        500: '#3c86b3',
        600: '#2e698c',
        700: '#1f4b64',
        800: '#0f2d3d',
        900: '#001018',
      },
      Country: {
        50: '#fff7dc',
        100: '#fbe8b1',
        200: '#f8d984',
        300: '#f4ca55',
        400: '#f1bb27',
        500: '#d8a20e',
        600: '#a87e07',
        700: '#785a03',
        800: '#493600',
        900: '#1b1200',
      },
      Folk: {
        50: '#ffeee0',
        100: '#f9d2b7',
        200: '#f1b48d',
        300: '#ea9862',
        400: '#e27a36',
        500: '#c9611d',
        600: '#9d4b15',
        700: '#71350e',
        800: '#451f04',
        900: '#1d0800',
      },
      Jazz: {
        50: '#e2edff',
        100: '#b3c8ff',
        200: '#83a4fb',
        300: '#5380f8',
        400: '#255bf6',
        500: '#0e42dc',
        600: '#0733ac',
        700: '#02257c',
        800: '#00164d',
        900: '#00071f',
      },
      Punk: {
        50: '#ffe6e1',
        100: '#ffbcb3',
        200: '#fb9083',
        300: '#f86553',
        400: '#f63a23',
        500: '#dc2109',
        600: '#ac1807',
        700: '#7c1004',
        800: '#4c0700',
        900: '#200000',
      },
      Rb: {
        50: '#f2e8ff',
        100: '#d4bbf8',
        200: '#b690f0',
        300: '#9963e8',
        400: '#7c38e1',
        500: '#631ec7',
        600: '#4d179c',
        700: '#370f70',
        800: '#210945',
        900: '#0d011b',
      },
      Dance: {
        50: '#ddfffd',
        100: '#b6f6f3',
        200: '#8eefe9',
        300: '#63e8e0',
        400: '#3de2d7',
        500: '#26c8bd',
        600: '#179c93',
        700: '#086f69',
        800: '#00443f',
        900: '#001816',
      },
    },
  },
});
