import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Input,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ImageUpload } from '../../../common/imageUpload';
import { UserContext } from '../../../app/App';
import { useNavigate } from 'react-router-dom';
import { createRoute, toUrl } from '../../../../util/routes';
import { useCreateEntry } from './useCreateEntry';
import { GenreCategory } from '../../common/types';

export const CreateEntry = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(toUrl(createRoute('cscsHome')));
    }
  }, [user]);

  const [artistName, setArtistName] = useState<string>('');
  const [songName, setSongName] = useState<string>('');
  const [shortDescription, setShortDescription] = useState<string>('');
  const [longDescription, setLongDescription] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [genres, setGenres] = useState<string>('');
  const [genreCategory, setGenreCategory] = useState<GenreCategory>(
    GenreCategory.Pop,
  );

  const { createEntry } = useCreateEntry({
    artistName,
    songName,
    shortDescription,
    longDescription,
    image,
    genres,
    genreCategory,
  });

  return (
    <Box>
      <Container centerContent>
        <Text size="lg" fontWeight="black">
          Create Entry
        </Text>
        <Input
          value={artistName}
          placeholder="Artist Name"
          onChange={(e) => {
            setArtistName(e.target.value);
          }}
        />
        <Input
          value={songName}
          placeholder="Song Name"
          onChange={(e) => {
            setSongName(e.target.value);
          }}
        />
        <Input
          value={shortDescription}
          placeholder="Short Description"
          onChange={(e) => {
            setShortDescription(e.target.value);
          }}
        />
        <Select
          value={genreCategory}
          onChange={(e) => {
            setGenreCategory(e.target.value as GenreCategory);
          }}
        >
          {(
            Object.keys(GenreCategory) as Array<keyof typeof GenreCategory>
          ).map((key) => {
            return (
              <option key={key} value={key}>
                {GenreCategory[key]}
              </option>
            );
          })}
        </Select>
        <Input
          value={genres}
          placeholder="Genres (comma separated)"
          onChange={(e) => {
            setGenres(e.target.value);
          }}
        />
        <Textarea
          value={longDescription}
          placeholder="Long Description"
          onChange={(e) => {
            setLongDescription(e.target.value);
          }}
        />
        <ImageUpload setImage={setImage} />
        <Button onClick={createEntry}>Submit</Button>
      </Container>
    </Box>
  );
};
