import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BreadcrumbGroup } from '../../common/breadcrumbs';

export const About = (): JSX.Element => {
  return (
    <Box>
      <BreadcrumbGroup breadcrumbPaths={['home', 'about']} />
      <Text>Coming soon</Text>
    </Box>
  );
};
