import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { type Response, map } from '../../service/response';

const DefaultLoadingView = (): JSX.Element => <Spinner />;
const DefaultErrorView = (): JSX.Element => <div>Error :(</div>;

export function StateView<D, E>(
  fetcher: () => Response<D, E>,
  SuccessView: React.ComponentType<D>,
  ErrorView: React.ComponentType<{ error: E }> = DefaultErrorView,
  LoadingView: React.ComponentType = DefaultLoadingView,
): React.FC {
  const wrapper: React.FC = () =>
    map(
      {
        fromSuccess: (data: D) => <SuccessView {...data} />,
        fromFailure: (error: E) => <ErrorView error={error} />,
        fromLoading: () => <LoadingView />,
      },
      fetcher(),
    );

  return wrapper;
}
