import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from '.';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from '../common/banners';
import { REFRESH_TOKEN } from '../../util/cognito';
import { type User } from '../../util/types';
import { useRefresh } from './useRefresh';
import { theme } from './theme';

export const UserContext = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}>({ user: null, setUser: () => {} });

export const App = (): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const { refreshUser } = useRefresh(localStorage.getItem(REFRESH_TOKEN));
  useEffect(() => {
    async function refresh(): Promise<void> {
      await refreshUser(setUser);
    }
    refresh().catch(() => {
      console.info('Unauthenticated user');
    });
  }, [refreshToken]);
  return (
    <ChakraProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter basename="/">
          <UserContext.Provider value={{ user, setUser }}>
            <Header />
            <Routes />
          </UserContext.Provider>
        </BrowserRouter>
      </HelmetProvider>
    </ChakraProvider>
  );
};
