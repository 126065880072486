import { isSuccess, isFailure, type Success } from '../../service/response';
import { useRefreshCognito } from '../../service/requests';
import { type AuthenticateOutput, type User, type Map } from '../../util/types';
import { ID_TOKEN, USER_ID } from '../../util/cognito';
import jwt_decode from 'jwt-decode';

export const useRefresh = (refreshToken: string | null): any => {
  const { refresh } = useRefreshCognito();

  const refreshUser = async (
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
  ): Promise<void> => {
    if (refreshToken) {
      const resp = await refresh({ refreshToken });

      if (isSuccess(resp)) {
        const { data } = resp as Success<AuthenticateOutput>;
        const userId = jwt_decode<Map>(data.idToken)['cognito:username'];

        setUser({
          idToken: data.idToken,
          refreshToken: data.refreshToken ?? '',
          userId,
        });
        localStorage.setItem(ID_TOKEN, data.idToken);
        localStorage.setItem(USER_ID, userId);
      }
      if (isFailure(resp)) {
        localStorage.clear();
      }
    }
  };
  return { refreshUser };
};
