import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  type BreadcrumbLinkProps,
} from '@chakra-ui/react';
import { pageNames, type RouteName, paths } from '../../../util/routes';
import { InternalBreadcrumbLink } from '../link';

import { ChevronRightIcon } from '@chakra-ui/icons';

interface BreadcrumbGroupProps {
  breadcrumbPaths: RouteName[];
}

export const BreadcrumbGroup: React.FC<BreadcrumbGroupProps> = ({
  breadcrumbPaths,
}) => {
  const finalBreadcrumbProps: BreadcrumbLinkProps = {
    isCurrentPage: true,
    color: 'gray.500',
  };

  return (
    <Breadcrumb
      fontWeight="md"
      fontSize="sm"
      mb={10}
      separator={<ChevronRightIcon color="gray.500" h={5} w={5} />}
    >
      {breadcrumbPaths.map((path: RouteName, index: number) => {
        return (
          <BreadcrumbItem key={path}>
            <InternalBreadcrumbLink
              props={
                index === breadcrumbPaths.length - 1
                  ? finalBreadcrumbProps
                  : undefined
              }
              to={(paths as any)[path]}
            >
              {pageNames[path]}
            </InternalBreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
